<template>
  <div class="login uk-cover-container uk-background-secondary uk-flex uk-flex-center uk-flex-middle uk-height-viewport uk-overflow-hidden uk-light" data-uk-height-viewport>
	
		<div class="uk-width-medium uk-padding-small uk-position-z-index" uk-scrollspy="cls: uk-animation-fade">
			
			<div class="uk-text-center uk-margin-medium-top">
				<h2 >Cuenta Cannonsoft</h2>
			</div>

			<!-- login -->
			<form id="form" >
				<fieldset class="uk-fieldset">
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: user"></span>
							<input v-model="name" class="uk-input uk-border-pill" required placeholder="Nombre" type="text">
						</div>
					</div>
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: mail"></span>
							<input v-model="mail" class="uk-input uk-border-pill" required placeholder="Correo" type="email">
						</div>
					</div>
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: mail"></span>
							<input v-model="cmail" class="uk-input uk-border-pill" required placeholder="Confirma correo" type="email">
						</div>
					</div>
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: lock"></span>
							<input v-model="pass" class="uk-input uk-border-pill" required placeholder="Contraseña" type="password">
						</div>
					</div>
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: lock"></span>
							<input v-model="cpass" class="uk-input uk-border-pill" required placeholder="Confirma contraseña" type="password">
						</div>
					</div>
					<div class="uk-margin-small">
						<div class="uk-inline uk-width-1-1">
							<span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: link"></span>
							<input v-model="code" class="uk-input uk-border-pill" data-uk-tooltip title="Solicítalo a tu empresa o a tu agente de Cannonsoft" placeholder="Código de registro (opcional)" type="text">
						</div>
					</div>
					<div class="uk-margin-small">
						<label><input required class="uk-checkbox" type="checkbox"> Acepto las <a target="_blank" style="color:#903396" href="https://cannonsoft.dev/privpol.html">políticas de privacidad</a> y los <a target="_blank"  style="color:#903396" href="https://cannonsoft.dev/terms.html">términos y condiciones</a> al registrarme</label>
					</div>
					<div class="uk-margin-bottom">
                        <p v-if="loading" class="uk-text-center">
                            <span uk-spinner="ratio:3"></span>
                        </p>
						<button v-else type="submit" class="uk-button uk-button-primary uk-border-pill uk-width-1-1">REGISTRARME</button>
					</div>
				</fieldset>
			</form>
			<!-- /login -->

		</div>

  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
export default {
    setup(){
        const name = ref('')
        const mail = ref('')
        const cmail = ref('')
        const pass = ref('')
        const cpass = ref('')
        const code = ref('')
        const loading = ref(false)
        const router = useRouter()
        const route = useRoute()


        const setupLicences = async ()=> {
                    var licences = {}
                    loading.value = true
                    await firebase.database().ref('site/codes/'+String(code.value).toUpperCase()).once('value').then((s)=>{
                        if(!s.exists()){
                            UIkit.notification('El código es inválido','danger')
                            loading.value = false
                            return
                        }
                        licences = s.toJSON()
                    })
                    loading.value = false
                    return licences
        }

        
        const autologin = () => {
            if(firebase.auth().currentUser === null){
                return
            } else {
                router.push('/licences')
            }
        }
	

        firebase.auth().onAuthStateChanged(function(user) {
            autologin()
        });


        onMounted(()=>{

                window.scroll(0,0)


                autologin()
            document.getElementById('form').addEventListener('submit', async (e)=>{

                e.preventDefault()

                if(mail.value != cmail.value){
                    UIkit.notification('Los correos no coinciden', 'danger')
                    return
                }
                if(pass.value != cpass.value){
                    UIkit.notification('Las contraseñas no coinciden', 'danger')
                    return
                }

                loading.value = true
                
                var obj = {
                    mail:mail.value,
                    name:name.value,
                }

                if(code.value){
                    obj.licences = setupLicences()
                }

                firebase.auth().createUserWithEmailAndPassword(mail.value, pass.value)
                .then(async (userCredential) => {
                    // Signed in 
                    var user = userCredential.user.uid;

                    await firebase.database().ref('users/'+user).update(obj)

                    obj.uid = user

                    if(route.query){
                        if(route.query.to){
                            router.push(  '/'  +  decodeURIComponent(to) )
                        }
                    }

                    router.push('/licences')
                    
                    
                    // ...
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    UIkit.notification( errorMessage, 'danger')
                    loading.value = false
                    // ..
                });
            })

        })


        return{
            name,
            mail,
            cmail,
            pass,
            cpass,
            code,
            loading,
            router,
        }

    }
}
</script>

<style>

</style>